import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';

import { Autocomplete, Box, Button, LinearProgress, MenuItem, Paper, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';
import { AddValueAndLabel } from '../../../../../Utils';
import UserContext from '../../../../../context/UserContext';

const FeeRuleForm = (props) => {
    const { handleClose, data, modalType, rows, setRows, ts, getRows } = props;

    const { useFeeSchedule, superAdminCheck, entityId } = useContext(UserContext)

    const [feeGroupers, setFeeGroupers] = useState([]);
    const [caseTypes, setCaseTypes] = useState([]);

    const [loading, setLoading] = useState(false);
    const [displayAmount, setDisplayAmount] = useState(
        modalType === 'edit'
            ? `$${parseFloat(data?.amount).toFixed(2)}`
            : ``
    );

    const getFeeGroupers = (caseType) => {
        axios.get(`${apiRoute}/api/FeeRuleTbls/feeGrouper/${caseType}`)
            .then((res) => {
                const sortedFeeGroupers = res.data.sort((a, b) => {
                    const feeGrouperA = typeof a === 'string' ? parseInt(a, 10) : a;
                    const feeGrouperB = typeof b === 'string' ? parseInt(b, 10) : b;

                    // Check if both values are numeric
                    if (!isNaN(feeGrouperA) && !isNaN(feeGrouperB)) {
                        return feeGrouperA - feeGrouperB;
                    } else if (typeof feeGrouperA === 'string' && typeof feeGrouperB === 'string') {
                        return feeGrouperA.localeCompare(feeGrouperB);
                    } else {
                        return 0; // Handle other cases as needed
                    }
                });

                setFeeGroupers(sortedFeeGroupers);
            })
            .catch((err) => {
                console.log(err, 'err getting Fee Groupers');
            });
    };

    useEffect(() => {
        if (!!state?.caseType) {
            getFeeGroupers(state?.caseType)
        }
    }, []);

    const getCaseTypes = () => {
        axios.get(`${apiRoute}/api/SyscodeTbls/ByType/1`)
            .then((res) => {
                const sortedCaseTypes = res.data
                    .sort((a, b) => a.defaultValue.localeCompare(b.defaultValue))
                    .filter(item => item.description !== "Case Types");

                setCaseTypes(sortedCaseTypes);
            })
            .catch((err) => console.log(err, 'err getting Case Types'));
    };

    useEffect(() => { getCaseTypes() }, [])

    // To Show Fee Grouper Options in AutoComplete Dropdown
    const feeGrouperData = AddValueAndLabel(feeGroupers, 'feeGrouper', 'feeGrouper');

    // To Show Case Type Options in AutoComplete Dropdown
    const caseTypeData = AddValueAndLabel(caseTypes, 'code', 'defaultValue');

    // Edit Fee Rule Form Initial Values
    const initialState = {
        courtNum: modalType === 'edit' ? data?.courtNum : '',
        rsNumber: modalType === 'edit' ? data?.rsNumber : '',
        caseType: modalType === 'edit' ? data?.caseType : '',
        feeType: modalType === 'edit' ? data?.feeType : '',
        qtyType: modalType === 'edit' ? data?.qtyType : '',
        waivableDwi: modalType === 'edit' ? data?.waivableDwi : '',
        specialHandling: modalType === 'edit' ? data?.specialHandling : '',
        prompt: modalType === 'edit' ? data?.prompt : '',
        description: modalType === 'edit' ? data?.description : '',
        multiplier: modalType === 'edit' ? data?.multiplier : '',
        amount: modalType === 'edit' ? data?.amount : '',
        descOverride: modalType === 'edit' ? data?.descOverride : '',
        amountOverried: modalType === 'edit' ? data?.amountOverried : '',
        showOnRcpt: modalType === 'edit' ? data?.showOnRcpt : '',
        eligibleForTimeServed: modalType === 'edit' ? data?.eligibleForTimeServed : '',
        feeGrouper: modalType === 'edit' ? data?.feeGrouper : '',
        isCourtCost: modalType === 'edit' ? data.isCourtCost : 'Y',
        act250: modalType === 'edit' ? data.act250 : 'N',
        act405: modalType === 'edit' ? data.act405 : 'N',
        act440: modalType === 'edit' ? data.act440 : 'N',
        act832: modalType === 'edit' ? data.act832 : 'N',
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'caseType':
                if (!value) {
                    error = 'Case type is required';
                }
                break;
            case 'feeType':
                if (!value) {
                    error = 'Fee type is required';
                }
                break;
            case 'description':
                if (!value) {
                    error = 'Description is required';
                }
                break;
            case 'amount':
                if (!value) {
                    error = 'Amount is required'
                }
                break;
            case 'isCourtCost':
                if (!value) {
                    error = 'Is court cost is required'
                }
                break;
            default:
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const [radioGroupValue, setRadioGroupValue] = useState(
        state.act250 === 'Y' ?
            'act250'
            : state.act405 === 'Y' ?
                'act405'
                : state.act440 === 'Y' ?
                    'act440'
                    : state.act832 === 'Y' ?
                        'act832' : null
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (modalType === 'add') {
            const feeRule = {
                CourtNum: state.courtNum,
                RsNumber: state.rsNumber,
                CaseType: state.caseType,
                FeeType: 'AMT',
                QtyType: null,
                WaivableDwi: state.waivableDwi || null,
                SpecialHandling: null,
                Prompt: null,
                ApplyTo: 'FEE',
                Description: state.description,
                Multiplier: state.multiplier,
                Amount: parseFloat(state.amount),
                DescOverride: state.descOverride || null,
                AmountOverride: state.amountOverride || null,
                ShowOnRcpt: state.showOnRcpt || null,
                eligibleForTimeServed: state.eligibleForTimeServed,
                SyscodeType: state.syscodeType || null,
                IsCourtCost: state.isCourtCost,
                Act250: state.act250,
                Act405: state.act405,
                Act440: state.act440,
                Act832: state.act832,
            }
            axios.post(`${apiRoute}/api/FeeRuleTbls`, feeRule)
                .then((res) => {
                    setRows([...rows, { ...res.data, id: res.data.pkFeeRuleId }]);
                    toast.success("Fee Rule added succefully!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err.response.status} while adding Fee Rule!`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
                .finally(() => { setLoading(false); getRows() })
        } else {
            const feeRule = {
                ...data,
                CourtNum: state.courtNum,
                RsNumber: state.rsNumber,
                CaseType: state.caseType,
                FeeType: 'AMT',
                QtyType: state.qtyType,
                WaivableDwi: state.waivableDwi,
                SpecialHandling: state.specialHandling,
                Prompt: state.prompt,
                ApplyTo: 'FEE',
                Description: state.description,
                Multiplier: state.multiplier,
                Amount: parseFloat(state.amount),
                DescOverride: state.descOverride,
                AmountOverride: state.amountOverride,
                ShowOnRcpt: state.showOnRcpt,
                eligibleForTimeServed: state.eligibleForTimeServed,
                SyscodeType: state.syscodeType || null,
                IsCourtCost: state.isCourtCost,
                Act250: state.act250,
                Act405: state.act405,
                Act440: state.act440,
                Act832: state.act832,
            }
            axios.put(`${apiRoute}/api/FeeRuleTbls/${data.id}`, feeRule)
                .then((res) => {
                    const updatedRows = rows.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    //getSysCodeTypes();
                    toast.success("Fee Rule updated succefully!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err.response.status} while updating Fee Rule!`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
                .finally(() => { setLoading(false); getRows() })
        }
    };

    return (
        <>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>{modalType === 'edit' ? 'Edit Fee Rule' : 'Add Fee Rule'}</strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={1 / 4} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="courtNum"
                                    label="Court Number"
                                    name="courtNum"
                                    value={state.courtNum}
                                    onChange={(e) => {
                                        handleFieldChange('courtNum', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('courtNum', e.target.value) }}
                                    helperText={state?.errors?.courtNum}
                                    error={!!state?.errors?.courtNum}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            {/*{entityId !== '2' && <Box width={1 / 4} mt={3} pr={3}>*/}
                            {/*    <TextField*/}
                            {/*        fullWidth*/}
                            {/*        sx={ts}*/}
                            {/*        id="rsNumber"*/}
                            {/*        label="RS Number"*/}
                            {/*        name="rsNum"*/}
                            {/*        value={state.rsNumber}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            handleFieldChange('rsNumber', e.target.value);*/}
                            {/*        }}*/}
                            {/*        variant="outlined"*/}
                            {/*        InputLabelProps={{ shrink: true }}*/}
                            {/*    />*/}
                            {/*</Box>}*/}
                            <Box width={1 / 4} mt={3} pr={3}>
                                <Autocomplete
                                    fullWidth
                                    name="caseType"
                                    sx={ts}
                                    options={caseTypeData}
                                    value={state.caseType}
                                    onChange={(e) => {
                                        handleFieldChange('caseType', e.target.textContent);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            label="Case Type"
                                            sx={ts}
                                            onBlur={(e) => {
                                                handleBlur('caseType', e.target.value);
                                                if (!!state?.caseType) {
                                                    getFeeGroupers(e.target.value);
                                                }
                                            }}
                                            helperText={state?.errors?.caseType}
                                            error={!!state?.errors?.caseType}
                                        />
                                    )}
                                />
                            </Box>
                            {
                                superAdminCheck &&
                                <Box width={1 / 4} mt={3} pr={3}>
                                    <Autocomplete
                                        fullWidth
                                        name="feeGrouper"
                                        sx={ts}
                                        options={feeGrouperData}
                                        value={state.feeGrouper}
                                            onChange={(e) => {
                                            handleFieldChange('feeGrouper', e.target.innerText);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Fee Grouper"
                                                sx={ts}
                                            />
                                        )}
                                        disabled={!state?.caseType}
                                    />
                                </Box>
                            }
                            <Box width={1 / 4} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="description"
                                    label="Description"
                                    name="description"
                                    value={state.description}
                                    onChange={(e) => {
                                        handleFieldChange('description', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('description', e.target.value) }}
                                    helperText={state?.errors?.description}
                                    error={!!state?.errors?.description}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={1 / 4} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="multiplier"
                                    label="Multiplier"
                                    name="multiplier"
                                    value={state.multiplier}
                                    onChange={(e) => {
                                        handleFieldChange('multiplier', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={1 / 4} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="amount"
                                    label="Amount"
                                    name="amount"
                                    value={displayAmount}
                                    onClick={() => setDisplayAmount(state.amount)}
                                    onChange={(e) => {
                                        handleFieldChange('amount', e.target.value);
                                        setDisplayAmount(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        handleBlur('amount', e.target.value)
                                        if (e.target.value) {
                                            setDisplayAmount(`$${parseFloat(e.target.value).toFixed(2)}`)
                                        }
                                    }}
                                    helperText={state?.errors?.amount}
                                    error={!!state?.errors?.amount}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        style: { textAlign: 'right' },
                                        inputMode: 'numeric',
                                    }}
                                />
                            </Box>
                            <Box width={1 / 4} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="eligibleForTimeServed"
                                    label="Eligible for Time Served"
                                    name="eligibleForTimeServed"
                                    value={state.eligibleForTimeServed}
                                    onChange={(e) => {
                                        handleFieldChange('eligibleForTimeServed', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('eligibleForTimeServed', e.target.value) }}
                                    helperText={state?.errors?.eligibleForTimeServed}
                                    error={!!state?.errors?.eligibleForTimeServed}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={1 / 4} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    select
                                    sx={ts}
                                    id="isCourtCost"
                                    label="Is Court Cost?"
                                    name="isCourtCost"
                                    value={state.isCourtCost}
                                    onChange={(e) => {
                                        handleFieldChange('isCourtCost', e.target.value);
                                    }}
                                    onBlur={(e) => { handleBlur('isCourtCost', e.target.value) }}
                                    helperText={state?.errors?.isCourtCost}
                                    error={!!state?.errors?.isCourtCost}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                >
                                    <MenuItem value={'Y'}>Yes</MenuItem>
                                    <MenuItem value={'N'}>No</MenuItem>
                                </TextField>
                            </Box>
                            <Box width={2 / 4} mt={2} pr={3}>
                                <FormControl>
                                    <FormLabel id="act-reports-radio-button-group-label">Autofill On Report:</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="act-reports-radio-button-group-label"
                                        name="row-radio-buttons-group"
                                        value={radioGroupValue}
                                        onChange={(e) => {
                                            setRadioGroupValue(e.target.value);
                                            if (e.target.value === 'act205') {
                                                handleFieldChange('act250', 'Y');
                                                handleFieldChange('act405', 'N');
                                                handleFieldChange('act440', 'N');
                                                handleFieldChange('act832', 'N');
                                            }
                                            if (e.target.value === 'act405') {
                                                handleFieldChange('act405', 'Y');
                                                handleFieldChange('act250', 'N');
                                                handleFieldChange('act440', 'N');
                                                handleFieldChange('act832', 'N');
                                            }
                                            if (e.target.value === 'act440') {
                                                handleFieldChange('act440', 'Y');
                                                handleFieldChange('act405', 'N');
                                                handleFieldChange('act250', 'N');
                                                handleFieldChange('act832', 'N');
                                            }
                                            if (e.target.value === 'act832') {
                                                handleFieldChange('act440', 'N');
                                                handleFieldChange('act405', 'N');
                                                handleFieldChange('act250', 'N');
                                                handleFieldChange('act832', 'Y');
                                            }
                                        }}
                                        onClick={() => {
                                            if (radioGroupValue !== '' || radioGroupValue !== null) {
                                                setRadioGroupValue(null);
                                                handleFieldChange('act250', 'N');
                                                handleFieldChange('act405', 'N');
                                                handleFieldChange('act440', 'N');
                                                handleFieldChange('act832', 'N');
                                        } }}
                                    >
                                        <FormControlLabel value="act205" control={<Radio />} label="Act 250" />
                                        <FormControlLabel value="act405" control={<Radio />} label="Act 405" />
                                        <FormControlLabel value="act440" control={<Radio />} label="Act 440" />
                                        <FormControlLabel value="act832" control={<Radio />} label="Act 832" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" gap={2} mt={0} p={1} justifyContent="right">
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default FeeRuleForm;
