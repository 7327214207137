/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import {
    Badge,
    Box,
    Button,
    ButtonGroup,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    ListItemIcon,
    Menu,
    MenuItem,
    Modal,
    Paper,
    TextField,
    Tooltip
} from '@mui/material';

import {
    MdAttachMoney,
    MdOutlineLockClock,
    MdOutlineLockOpen,
    MdOutlineMoneyOffCsred,
    MdSpeakerNotes,
    MdOutlineRestore
} from "react-icons/md";

import { IoMdReturnLeft } from "react-icons/io";
import { SlMenu } from "react-icons/sl";
import { LuArchive, LuArchiveRestore } from "react-icons/lu";
import { LiaTrashAltSolid, LiaTrashSolid, LiaTrashRestoreAltSolid, LiaTrashRestoreSolid } from "react-icons/lia";
import { RiDeleteBin2Line } from "react-icons/ri";

import { toast } from 'react-toastify';

import * as yup from 'yup';

import SuspendModal from "../components/modals/components/violationsTab/SuspendModal";
import CommentsBox from "../components/sections/violationsTab/CommentsBox";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { ThemeModeContext, apiRoute } from "../App";
import { commaMoneyFormat } from "../Utils";
import { CaseContext } from '../context/CaseContext';
import UserContext from "../context/UserContext";


const GenInfo = (props) => {
    const {
        updateBalanceRows,
        updateCaseBalance,
        refreshSearch,
        searchParams,
        caseNumber,
        handleFieldChange,
        handleBlur,
        updateCitation,
        state,
        ts,
        changes,
        setChanges,
        handleChangesModal,
        modalType,
        setModalType,
        changesModal,
        setChangesModal,
        resetState,
        from,
        setFrom,
        ArchiveCase,
        violationModal,
        setViolationModal,
        violationModalType,
        setViolationModalType,
        violationModalWidth,
        setViolationModalWidth,
        openArchiveModal,
        setArchiveModal,
        handleArchiveCaseEvent,
        openDeleteModal,
        setDeleteModal,
        handleDeleteCaseEvent
    } = props;

    const { mode } = useContext(ThemeModeContext);

    const {
        agencyCode,
        courtNum,
        divisionCode,
        balance,
        caseView,
        balanceRows,
        violationDate,
        citationView,
        violationRows,
        chargesRows,
        caseHistoryRows,
        caseTypes,
        statusCodes,
        suffixCodes,
        setCaseView,
        bondRows,
        cashBondRows,
    } = useContext(CaseContext);

    const [comments, setComments] = useState(caseView.comments || "");
    const [tempComments, setTempComments] = useState(comments);
    const [commentsErrors, setErrors] = useState({});
    const [suspendingCase, setSuspendingCase] = useState(false);
    const [loadingSuspend, setLoadingSuspend] = useState(false);

    const [courtDate, setCourtDate] = useState();
    const [courtDateTime, setCourtDateTime] = useState(new Date(state?.courtDatetime));
    const [timezoneOffset, setTimeOffset] = useState(courtDateTime?.getTimezoneOffset());

    const navigate = useNavigate();
    const [openCasePaymentLock, setOpenCasePaymentLock] = useState(false);
    const [reason, setReason] = useState(caseView.reason);
    const [loading, setLoading] = useState(false);

    const [archiveReason, setArchiveReason] = useState(state.archiveReason);
    const [deleteReason, setDeleteReason] = useState(state.deletedReason);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setCourtDateTime(new Date(state?.courtDatetime));
    }, [state?.courtDatetime]);

    useEffect(() => {
        // Adjust courtDateTime to local time zone
        const adjustedCourtDateTime = new Date(courtDateTime.getTime() + (timezoneOffset * 60000));
        // Set time to midnight
        setCourtDate(adjustedCourtDateTime);
    }, [courtDateTime, timezoneOffset]);

    // Get the current date and set time to midnight
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Comments Modal Handling
    const [openCommentsModal, setOpenCommentsModal] = useState(false);
    const handleCommentsModal = () => {
        setOpenCommentsModal(!openCommentsModal);
    };

    const [suspendBalanceModal, setSuspendBalanceModal] = useState(false);
    const handleSuspendBalanceModal = () => {
        setModalType('balance');
        setSuspendBalanceModal(!suspendBalanceModal);
    };
    const [confirmSuspendedBalance, setConfirmSuspendedBalance] = useState(false);
    const handleConfirmSuspendedBalance = () => {
        setConfirmSuspendedBalance(!confirmSuspendedBalance);
        setSuspendBalanceModal(false);
    };

    // Format number as money with 2 decimal places and dollar sign
    const formatMoney = (value) => {
        // Check if the value is a valid number
        if (typeof value !== "number" || isNaN(value)) {
            return ""; // Return an empty string if the value is not a valid number
        }

        return `$${value.toFixed(2).toLocaleString()}`;
    };

    const sortedStatusCodes = statusCodes.sort((a, b) => a.defaultValue.localeCompare(b.defaultValue));

    const handleLockPayment = (reason) => {
        if (reason != "") {
            const isLocked = caseView.isCasePaymentLocked;

            const path = isLocked
                ? `${apiRoute}/api/caselock/unlock/${caseNumber}`
                : `${apiRoute}/api/caselock/lock/${caseNumber}`;
            setLoading(true);


            axios.post(path, JSON.stringify(reason),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    toast.success(isLocked ? 'Case Payments unlocked successfully!' : 'Case Payments locked successfully!', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });

                    setCaseView(prevCaseView => ({
                        ...prevCaseView,
                        isCasePaymentLocked: !isLocked, // or any other specific field you want to update
                        reason: isLocked ? reason : ""
                    }));

                    setReason(reason);
                    isLocked === false ? axios.patch(`${apiRoute}/api/citationtbls/${caseView.fkCitationIdName}`, { caseStatus: 'HOLD' },
                        {
                            params: {
                                violationDate: violationDate,
                                casenumber: caseNumber,
                            }
                        }) && handleFieldChange('caseStatus', 'HOLD') && setChanges(false) : handleFieldChange('caseStatus', '') && setChanges(false);

                })
                .catch((err) => {
                    toast.error(`Error ${err.response.status} while changing payment flag.`, {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                })
                .finally(() => {
                    updateCaseStatus();
                    setLoading(false);
                    setOpenCasePaymentLock(false);
                    setChanges(false);
                }
                );
        } else {
            toast.error(`Please provide reason.`, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
    }
    const updateCaseComments = (e, c) => {
        let temp = c;
        e.preventDefault();

        const updateCommentsData = {
            ...caseView,
            comments: temp,
        };

        axios.put(`${apiRoute}/api/casetbls/${caseNumber}`, updateCommentsData)
            .then(() => {
                toast.success("Comments saved", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setComments(temp);
                setTempComments(temp);
                caseView.comments = temp;
                setOpenCommentsModal(false);
            })
            .catch((err) => {
                console.log("❌ ERR SAVING COMMENTS", err);
                toast.error("Error saving comments", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });

    };

    const caseBalanceStyle = {
        "& .MuiInputBase-root": {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black",
        },
        "& .MuiInputLabel-root": {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === "dark" ? "1px 1px 2px black" : "none",
        },
        width: "40%",
    };

    const suspendCaseBalance = async (e, c) => {
        setSuspendingCase(true)
        const upper = c.toUpperCase();
        const status = upper.includes('DECEASED') || upper.includes('DEC.') || upper.includes('DEC') ? 'DECEASED' : 'SUSPENDED';
        setLoadingSuspend(true);
        try {
            setTempComments(c);
            await updateCaseComments(e, c);
            balanceRows.forEach(row => {
                row.suspendAmount = row.assessAmount;
            });
            await axios.put(`${apiRoute}/api/FeeFineTbls/SaveChanges`, balanceRows);
            await axios.patch(`${apiRoute}/api/citationtbls/${caseView.fkCitationIdName}`, { caseStatus: status },
                {
                    params: {
                        violationDate: violationDate,
                        casenumber: caseNumber,
                    }
                });
            try {
                await updateBalanceRows();
                await updateCaseBalance();
                handleFieldChange('caseStatus', status);
                setOpenCommentsModal(false);
                handleSuspendBalanceModal();
                setModalType("")
                setChanges(false);
                toast.success("Case Suspended", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } catch (err) {
                console.warn(err);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingSuspend(false);
            setSuspendingCase(false)
        }
    };


    const updateCaseStatus = () => {
        let status;
        let netAmountPaid = 0;
        let netAmountDue = 0;
        for (const payment of caseHistoryRows) {
            netAmountPaid += payment.paymentAmount;
        }
        for (const charge of chargesRows) {
            netAmountDue += charge.remainingBalance;
        }
        const remainingBalance = parseFloat(netAmountPaid) + parseFloat(netAmountDue);
        if (!state.isActive) {
            status = 'ARCHIVED'
        }
        if (state.isDeleted) {
            status = 'DELETED'
        }
        else if (state.caseStatus === 'HOLD' || state.caseStatus === 'DECEASED' || state.caseStatus === 'SUSPENDED' || state.caseStatus === 'INDIGENT' || state.caseStatus === 'TRANSFERRED TO COURT' || state.caseStatus === 'WAIVED' || state.caseStatus === 'DISMISSED') {
            return;
        } else if (violationRows.length >= 1 && chargesRows.length >= 1 && caseHistoryRows.length >= 1 && parseFloat(balance).toFixed(2) <= 0.00) {
            status = 'PAID IN FULL';
        } else if (caseHistoryRows.length >= 1 && parseFloat(balance).toFixed(2) <= 0.00) {
            status = 'PAID IN FULL';
        } else if (violationRows.length >= 1 && chargesRows.length && caseHistoryRows.length === 1 && caseHistoryRows[0].refundReason === 'CASH BOND REFUND') {
            status = 'UNPAID';
        } else if (violationRows.length >= 1 && chargesRows.length && caseHistoryRows.length >= 1 && parseFloat(balance).toFixed(2) > 0.00 && remainingBalance !== netAmountDue) {
            status = 'PARTIALLY PAID';
        } else {
            status = 'UNPAID';
        }
        if (status !== state.caseStatus) {
            axios
                .patch(`${apiRoute}/api/citationtbls/${caseView.fkCitationIdName}`, { caseStatus: status }, {
                    params: {
                        violationDate: violationDate,
                        casenumber: caseView.clerkCaseNum,
                        caseType: caseView.caseType,
                        arrestNumber: caseView.arrestNumber
                    }
                })
                .then(() => {
                    handleFieldChange('caseStatus', status);
                    refreshSearch(searchParams);
                    setChanges(false);
                })
                .catch((err) => {
                    console.log("❌ ERR SAVING Case Status", err);
                    toast.error("Error saving Case Status", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                });
        }
    };

    useEffect(() => {
        updateCaseStatus();
    }, [violationRows, chargesRows, balance, caseHistoryRows, citationView, caseView.comments, caseView.isCasePaymentLocked, state.caseStatus, state.isActive, state.isDeleted]);

    return (
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3 style={{ fontWeight: 'bold', fontSize: '3vh', marginBottom: '0px' }}>
                    <Button variant="text" sx={{ backgroundColor: 'inherit', color: 'white', fontSize: '6px', marginRight: '0.2rem' }} onClick={() => navigate(-1)} >
                        <Tooltip title="Go Back">
                            <ArrowBackIcon />
                        </Tooltip>
                    </Button>
                    General Info
                </h3>
                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'right' }}>
                    <strong style={{ fontSize: '1vw' }}>Case Balance: </strong>
                    <TextField
                        sx={caseBalanceStyle}
                        size="small"
                        value={commaMoneyFormat(balance)}
                        id="balance"
                        inputProps={{ min: 0, style: { color: "inherit", textAlign: 'right', fontWeight: 600, fontSize: '1vw' }, readOnly: true }}
                    />
                    <Tooltip title="Case Menu">
                        <IconButton
                            onClick={handleClick}
                            size="large"
                            sx={{ ml: .5 }}
                            aria-controls={open ? 'case-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            color='inherit'
                        >
                            <SlMenu sx={{ width: 32, height: 32 }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                backgroundColor: mode === 'dark' ? '#313131' : 'white',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                            },
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    >

                        <MenuItem sx={{
                            pointerEvents: 'none',
                            cursor: 'default',
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                            '&::before, &::after': {
                                content: 'none',
                            },
                        }}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            onMouseDown={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                        >
                            Case Menu Options
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => setArchiveModal(true)}
                            disabled={state.isDeleted}
                        >
                            <ListItemIcon>
                                {state.isActive ? <LuArchive fontSize="x-large" /> : <LuArchiveRestore fontSize="x-large" />}
                            </ListItemIcon>
                            {state.isActive ? "Archive" : "Restore"} Case
                        </MenuItem>
                        <MenuItem
                            onClick={() => { setOpenCasePaymentLock(true) }}
                            disabled={!state.isActive | state.isDeleted}
                        >
                            <ListItemIcon>
                                {caseView.isCasePaymentLocked ? <MdOutlineLockOpen fontSize="x-large" /> : <MdOutlineLockClock fontSize="x-large" />}
                            </ListItemIcon>
                            {caseView.isCasePaymentLocked ? "Release Hold" : "Hold For Review"}
                        </MenuItem>
                        <MenuItem
                            onClick={handleSuspendBalanceModal}
                            disabled={balance.toString() <= '0.00' | !state.isActive | state.isDeleted}
                        >
                            <ListItemIcon>
                                <MdOutlineMoneyOffCsred fontSize="x-large" />
                            </ListItemIcon>
                            Suspend Case Balance
                        </MenuItem>
                        <MenuItem
                            onClick={() => setDeleteModal(true)}
                            disabled={!state.isActive | caseHistoryRows?.length > 0 | bondRows?.length > 0 | cashBondRows?.length > 0}
                        >
                            <ListItemIcon>
                                {state.isDeleted ? <MdOutlineRestore fontSize="x-large" /> : <RiDeleteBin2Line fontSize="x-large" />}
                            </ListItemIcon>
                            {state.isDeleted ? 'Recover Case' : 'Delete Case'}
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => navigate(-1)}
                        >
                            <ListItemIcon>
                                <IoMdReturnLeft fontSize="x-large" />
                            </ListItemIcon>
                            Exit Case
                        </MenuItem>
                    </Menu>
                </Box>
            </Paper>
            <Box sx={{ display: "flex", gap: '1rem', mt: '1vh', pl: '.5rem' }}>
                <Box sx={{ width: '90%' }}>
                    <Grid container>
                        <Grid item sm={'auto'} xs={'auto'}>
                            <Box m={.5}>
                                <TextField
                                    sx={{ ...ts, width: '8vw' }}
                                    id="caseTypes"
                                    label="Case Type"
                                    name="caseTypes"
                                    select
                                    value={state?.caseTypes}
                                    onChange={(e) => handleFieldChange('caseTypes', e.target.value)}
                                    onBlur={(e) => handleBlur('caseTypes', e.target.value)}
                                    helperText={state?.errors?.caseTypes}
                                    error={!!state?.errors?.caseTypes}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                >
                                    <MenuItem value={''}>N/A</MenuItem>
                                    {
                                        caseTypes?.map(({ code, description }) => {
                                            return <MenuItem key={code} value={code}>{description}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box margin={0.5}>
                                <TextField
                                    sx={ts}
                                    id="fullCaseNumber"
                                    label="Full Case #"
                                    name="fullCaseNumber"
                                    value={state?.fullCaseNumber}
                                    onChange={(e) => handleFieldChange('fullCaseNumber', e.target.value)}
                                    onBlur={(e) => handleBlur('fullCaseNumber', e.target.value)}
                                    helperText={state?.errors?.fullCaseNumber}
                                    error={!!state?.errors?.fullCaseNumber}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box margin={0.5}>
                                <TextField
                                    sx={{ ...ts, maxWidth: '9vw' }}
                                    id="clerkCaseNum"
                                    label="Docket #"
                                    name="clerkCaseNum"
                                    value={state?.clerkCaseNum}
                                    onChange={(e) => handleFieldChange('clerkCaseNum', e.target.value)}
                                    onBlur={(e) => handleBlur('clerkCaseNum', e.target.value)}
                                    helperText={state?.errors?.clerkCaseNum}
                                    error={!!state?.errors?.clerkCaseNum}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{ ...ts, maxWidth: '9.1vw' }}
                                    id="citNumber"
                                    label="Citation #"
                                    name="citNumber"
                                    value={state?.citNumber}
                                    onChange={(e) => handleFieldChange('citNumber', e.target.value)}
                                    onBlur={(e) => handleBlur('citNumber', e.target.value)}
                                    helperText={state?.errors?.citNumber}
                                    error={!!state?.errors?.citNumber}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{ ...ts, maxWidth: '9.1vw' }}
                                    id="arrestNumber"
                                    label="Arrest #"
                                    name="arrestNumber"
                                    value={state?.arrestNumber}
                                    onChange={(e) => handleFieldChange('arrestNumber', e.target.value)}
                                    onBlur={(e) => handleBlur('arrestNumber', e.target.value)}
                                    helperText={state?.errors?.arrestNumber}
                                    error={!!state?.errors?.arrestNumber}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{
                                        ...ts, maxWidth: '7.0vw'
                                    }}
                                    id="violationDate"
                                    label="Violation Date"
                                    name="violationDate"
                                    type="date"
                                    value={state?.violationDate}
                                    onChange={(e) => handleFieldChange('violationDate', e.target.value)}
                                    onBlur={(e) => handleBlur('violationDate', e.target.value)}
                                    helperText={state?.errors?.violationDate}
                                    error={!!state?.errors?.violationDate}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{
                                        ...ts, maxWidth: '7.0vw'
                                    }}
                                    id="dateFiled"
                                    label="Date Filed"
                                    name="dateFiled"
                                    type="date"
                                    value={state?.dateFiled}
                                    onChange={(e) => handleFieldChange('dateFiled', e.target.value)}
                                    onBlur={(e) => handleBlur('dateFiled', e.target.value)}
                                    helperText={state?.errors?.dateFiled}
                                    error={!!state?.errors?.dateFiled}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{
                                        ...ts, maxWidth: '7.0vw'
                                    }}
                                    id="courtDatetime"
                                    label="Court Date"
                                    name="courtDatetime"
                                    type="date"
                                    value={state?.courtDatetime}
                                    onChange={(e) => handleFieldChange('courtDatetime', e.target.value)}
                                    onBlur={(e) => handleBlur('courtDatetime', e.target.value)}
                                    helperText={state?.errors?.courtDatetime}
                                    error={!!state?.errors?.courtDatetime}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        style: {
                                            color: (!['PAID IN FULL', 'DECEASED', 'INDIGENT', 'SUSPENDED', 'TRANSFERRED TO COURT'].includes(state.caseStatus) && courtDate < currentDate) ? 'red' : (!['PAID IN FULL', 'DECEASED', 'INDIGENT', 'SUSPENDED', 'TRANSFERRED TO COURT'].includes(state.caseStatus) && courtDate <= currentDate) ? 'orange' : 'inherit'
                                        },
                                        readOnly: !state.isActive | state.isDeleted,
                                    }}
                                    color={courtDate < currentDate ? 'error' : 'warning'}
                                    focused={!['PAID IN FULL', 'DECEASED', 'INDIGENT', 'SUSPENDED', 'TRANSFERRED TO COURT'].includes(state.caseStatus) && courtDate <= currentDate}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'} xs={'auto'}>
                            <Box m={.5}>
                                <TextField
                                    sx={{
                                        ...ts, maxWidth: '12.5vw', minWidth: '12.5vw'
                                    }}
                                    id="caseStatus"
                                    label="Case Status"
                                    name="caseStatus"
                                    select
                                    value={state?.caseStatus}
                                    onChange={(e) => handleFieldChange('caseStatus', e.target.value)}
                                    onBlur={(e) => handleBlur('caseStatus', e.target.value)}
                                    helperText={state?.errors?.caseStatus}
                                    error={!!state?.errors?.caseStatus}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        style: {
                                            color: (['HOLD', 'ARCHIVED'].includes(state.caseStatus) && mode === 'dark') ? '#ffc326' : (['HOLD', 'ARCHIVED'].includes(state.caseStatus) && mode !== 'dark') ? '#ed6c02' : 'inherit'
                                        }
                                    }}
                                    focused={['HOLD', 'ARCHIVED'].includes(state.caseStatus)}
                                    color={['HOLD', 'ARCHIVED'].includes(state.caseStatus) && 'warning'}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                >
                                    {sortedStatusCodes?.map(({ defaultValue }) => (
                                        <MenuItem key={defaultValue} value={defaultValue}>
                                            {defaultValue}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={ts}
                                    id="lastName"
                                    label="Last"
                                    name="lastName"
                                    value={state?.lastName}
                                    onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                    onBlur={(e) => handleBlur('lastName', e.target.value)}
                                    helperText={state?.errors?.lastName}
                                    error={!!state?.errors?.lastName}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={ts}
                                    id="firstName"
                                    label="First"
                                    name="firstName"
                                    value={state?.firstName}
                                    onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                    onBlur={(e) => handleBlur('firstName', e.target.value)}
                                    helperText={state?.errors?.firstName}
                                    error={!!state?.errors?.firstName}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={ts}
                                    id="midInit"
                                    label="Middle"
                                    name="midInit"
                                    value={state?.midInit}
                                    onChange={(e) => handleFieldChange('midInit', e.target.value)}
                                    onBlur={(e) => handleBlur('midInit', e.target.value)}
                                    helperText={state?.errors?.midInit}
                                    error={!!state?.errors?.midInit}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{ ...ts, width: '4.5vw' }}
                                    id="suffix"
                                    label="Suffix"
                                    name="suffix"
                                    value={state?.suffix}
                                    onChange={(e) => handleFieldChange('suffix', e.target.value)}
                                    onBlur={(e) => handleBlur('suffix', e.target.value)}
                                    helperText={state?.errors?.suffix}
                                    error={!!state?.errors?.suffix}
                                    select
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                >
                                    {suffixCodes?.map(({ defaultValue }) => (
                                        <MenuItem key={defaultValue} value={defaultValue}>
                                            {defaultValue === '""' ? "N/A" : defaultValue}
                                        </MenuItem>
                                    )
                                    )}
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{ ...ts, width: '13vw' }}
                                    id="agency"
                                    label="Agency"
                                    name="agency"
                                    value={state?.agency}
                                    onChange={(e) => handleFieldChange('agency', e.target.value)}
                                    onBlur={(e) => handleBlur('agency', e.target.value)}
                                    helperText={state?.errors?.agency}
                                    error={!!state?.errors?.agency}
                                    select
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                >
                                    <MenuItem value={''}>N/A</MenuItem>
                                    {
                                        agencyCode?.map(({ code, description }) => {
                                            return <MenuItem key={code}
                                                value={code}>{description}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{ ...ts, width: '14vw' }}
                                    id="court"
                                    label="Court"
                                    name="court"
                                    value={state?.court}
                                    onChange={(e) => handleFieldChange('court', e.target.value)}
                                    onBlur={(e) => handleBlur('court', e.target.value)}
                                    helperText={state?.errors?.court}
                                    error={!!state?.errors?.court}
                                    select
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                >
                                    <MenuItem value={''}>N/A</MenuItem>
                                    {
                                        courtNum?.map(({ courtNum, displayCourtName }) => {
                                            return <MenuItem key={courtNum}
                                                value={courtNum}>{displayCourtName}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item sm={'auto'} xs={'auto'}>
                            <Box m={0.5}>
                                <TextField
                                    sx={{ ...ts, width: '17vw' }}
                                    id="division"
                                    label="Division"
                                    name="division"
                                    value={state?.division}
                                    onChange={(e) => handleFieldChange('division', e.target.value)}
                                    onBlur={(e) => handleBlur('division', e.target.value)}
                                    helperText={state?.errors?.division}
                                    error={!!state?.errors?.division}
                                    select
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: !state.isActive | state.isDeleted }}
                                >
                                    <MenuItem value={''}>N/A</MenuItem>
                                    {
                                        divisionCode?.map(({ divisionCode, name }) => {
                                            return <MenuItem key={divisionCode}
                                                value={divisionCode}>{name}</MenuItem>
                                        })
                                    }
                                </TextField>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Paper sx={{ textAlign: 'center', width: "15.5%", margin: "0 .5vw 1vh auto", height: 'auto', pb: 1.7 }} elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', height: 'auto', display: 'flex' }} elevation={10}>
                        <h4 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Case Options</h4>
                    </Paper>
                    <ButtonGroup orientation="vertical" fullWidth sx={{ paddingRight: ".5rem", paddingLeft: ".5rem", paddingTop: ".5rem", mt: '.50vh' }} variant="text">
                        <Box mb={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '.5rem' }}>
                            <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", height: '3vh', borderRadius: '8px' }} onClick={updateCitation} disabled={!changes}>
                                Save
                            </Button>
                            <Box>
                                <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", height: '3vh', borderRadius: '8px' }} onClick={resetState} disabled={!changes}>
                                    Discard
                                </Button>
                            </Box>

                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                onClick={handleCommentsModal}
                                sx={
                                    {
                                        backgroundColor: "steelblue",
                                        color: "white",
                                        height: '3vh',
                                        borderRadius: '8px'
                                    }
                                }
                                endIcon={caseView?.comments?.length > 0 && <Badge
                                    overlap="circular"
                                    color='warning'
                                    badgeContent="!!"
                                    sx={
                                        {
                                            '& .MuiBadge-badge': {
                                                color: 'white', // Change color here
                                                fontSize: 15
                                            },
                                        }
                                    }
                                >
                                    <MdSpeakerNotes
                                        fontSize={22}
                                    />
                                </Badge>
                                }
                            >
                                Comments
                            </Button>
                        </Box>
                    </ButtonGroup>
                    <Modal
                        open={openCommentsModal}
                        onClose={handleCommentsModal}
                        aria-labelledby="comments-modal"
                        sx={{
                            width: '50%', mx: 'auto', mt: '5vh',
                            "& > .MuiBackdrop-root": {
                                backdropFilter: 'blur(2px)'
                            }
                        }}
                    >
                        <>
                            <CommentsBox
                                handleClose={handleCommentsModal}
                                updateCaseComments={updateCaseComments}
                                textFieldStyle={ts}
                                comments={tempComments}
                                setComments={setTempComments}
                                errors={commentsErrors}
                            />
                        </>
                    </Modal>
                    <Modal open={suspendBalanceModal || changesModal} onClose={handleSuspendBalanceModal || handleChangesModal} aria-labelledby="suspend-balance-modal">
                        <>
                            <SuspendModal
                                modalType={modalType}
                                suspendBalanceModal={suspendBalanceModal}
                                handleSuspendBalanceModal={handleSuspendBalanceModal}
                                ts={ts}
                                cs={caseBalanceStyle}
                                balance={balance}
                                formatMoney={formatMoney}
                                setConfirmModal={handleConfirmSuspendedBalance}
                                reason={tempComments}
                                suspendCaseBalance={suspendCaseBalance}
                                suspending={suspendingCase}
                                setSuspending={setSuspendingCase}
                                changesModal={changesModal}
                                handleChangesModal={handleChangesModal}
                                updateCitation={updateCitation}
                                setChanges={setChanges}
                                setChangesModal={setChangesModal}
                                setModalType={setModalType}
                                resetState={resetState}
                                from={from}
                                setFrom={setFrom}
                                state={state}
                            />
                        </>
                    </Modal>
                    <Modal open={openCasePaymentLock}
                        onClose={() => { setOpenCasePaymentLock(false) }}
                        sx={{
                            width: "30%",
                            ml: "35vw",
                            mt: "25vh",
                            py: "4px",
                            "& > .MuiBackdrop-root": {
                                backdropFilter: 'blur(2px)'
                            }
                        }}
                    >
                        <>
                            <Paper elevation={10}>
                                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                                    <h2>
                                        <strong> {caseView.isCasePaymentLocked ? "Release Payments" : "Hold For Review"}</strong>
                                    </h2>
                                </Paper>
                                <Box sx={{ p: '1vh' }}>
                                    <Box mt={1.5} mb={1.5}>
                                        <TextField
                                            fullWidth
                                            id="reason"
                                            label="Reason"
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                    <hr />
                                    <Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
                                        <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} disabled={loading} onClick={() => handleLockPayment(reason)}>Save</Button>
                                        <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={() => setOpenCasePaymentLock(false)}>cancel</Button>
                                    </Box>

                                    {
                                        loading && (
                                            <>
                                                <LinearProgress />
                                            </>
                                        )
                                    }
                                </Box>
                            </Paper>
                        </>
                    </Modal>
                    <Modal open={openArchiveModal}
                        sx={{
                            width: "45%",
                            m: 'auto',
                            mt: "15vh",
                            minHeight: '55vh',
                            "& > .MuiBackdrop-root": {
                                backdropFilter: 'blur(2px)'
                            }
                        }}
                    >
                        <>
                            <Paper elevation={10}>
                                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                                    <h2>
                                        {
                                            state.isActive ?
                                                <strong>Would you like to ARCHIVE this case?<br />You will always be able to restore it at a later date. </strong>
                                                : <strong>Are you sure you would like to RESTORE this case?</strong>
                                        }
                                    </h2>
                                </Paper>
                                <Box sx={{ p: '1vh' }}>
                                    <Box mt={1.5} mb={1.5}>
                                        <TextField
                                            fullWidth
                                            id="reason"
                                            label="Reason"
                                            value={archiveReason}
                                            onChange={(e) => setArchiveReason(e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            multiline
                                            minRows={3}
                                            required
                                            error={setArchiveReason === '' && 'warning'}
                                        />
                                    </Box>
                                    <hr />
                                    <Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                            disabled={!archiveReason || archiveReason === ""}
                                            onClick={async () => await handleArchiveCaseEvent(!state.isActive, archiveReason)}
                                        >
                                            {state.isActive ? 'Archive' : 'Restore'}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                            onClick={() => setArchiveModal(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>

                                    {
                                        loading && (
                                            <>
                                                <LinearProgress />
                                            </>
                                        )
                                    }
                                </Box>
                            </Paper>
                        </>
                    </Modal>
                    <Modal open={openDeleteModal}
                        sx={{
                            width: "45%",
                            m: 'auto',
                            mt: "15vh",
                            minHeight: '55vh',
                            "& > .MuiBackdrop-root": {
                                backdropFilter: 'blur(2px)'
                            }
                        }}
                    >
                        <>
                            <Paper elevation={10}>
                                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                                    <h2>
                                        {
                                            !state.isDeleted ?
                                                <strong>Would you like to DELETE this case?<br />You will always be able to recover it at a later date. </strong>
                                                : <strong>Are you sure you would like to RECOVER this case?</strong>
                                        }
                                    </h2>
                                </Paper>
                                <Box sx={{ p: '1vh' }}>
                                    <Box mt={1.5} mb={1.5}>
                                        <TextField
                                            fullWidth
                                            id="reason"
                                            label="Reason"
                                            value={deleteReason}
                                            onChange={(e) => setDeleteReason(e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            multiline
                                            minRows={3}
                                            required
                                            error={deleteReason === '' && 'warning'}
                                        />
                                    </Box>
                                    <hr />
                                    <Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                            disabled={!deleteReason || deleteReason === ""}
                                            onClick={async () => await handleDeleteCaseEvent(!state.isDeleted, deleteReason)}
                                        >
                                            {!state.isDeleted ? 'Delete' : 'Recover'}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                            onClick={() => setDeleteModal(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>

                                    {
                                        loading && (
                                            <>
                                                <LinearProgress />
                                            </>
                                        )
                                    }
                                </Box>
                            </Paper>
                        </>
                    </Modal>

                </Paper>
            </Box>
        </Paper>
    )
}

export default GenInfo;