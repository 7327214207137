import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, ButtonGroup, Grid, LinearProgress, MenuItem, Paper, TextField } from '@mui/material';

import { ThemeModeContext } from '../../../App';
import { CaseContext } from '../../../context/CaseContext';

const CreateCaseForm = (props) => {
    const { loading, handleBlur, handleFieldChange, state, isSubmitting, createCitation } = props;

    const { mode } = useContext(ThemeModeContext);

    const {
        agencyCode,
        courtNum,
        divisionCode,
        caseTypes,
        statusCodes,
        suffixCodes,
        caseYear,
    } = useContext(CaseContext);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                    <h5 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Create New Case/Citation</h5>
                </Paper>
                <form>
                    <Box sx={{ display: "flex", gap: '1rem', mt: '1vh', pl: '.5rem' }}>
                        <Box sx={{ width: '78.99%' }}>
                            <Grid container>
                                <Grid item sm={1.5}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="caseTypes"
                                            label="Case Type"
                                            name="caseTypes"
                                            select
                                            fullWidth
                                            value={state?.caseTypes}
                                            onChange={(e) => handleFieldChange('caseTypes', e.target.value)}
                                            onBlur={(e) => handleBlur('caseTypes', e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            required
                                            error={!!state?.errors?.caseTypes}
                                            helperText={state?.errors?.caseTypes}
                                            color={!!state?.errors?.caseTypes && 'warning'}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                caseTypes?.map(({ code, description }) => {
                                                    return <MenuItem key={code} value={code}>{description}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>

                                <Grid item sm={.98}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="caseYear"
                                            label="Case Year"
                                            fullWidth
                                            select
                                            value={state?.caseYear}
                                            onChange={(e) => handleFieldChange('caseYear', e.target.value)}
                                            onBlur={(e) => handleBlur('caseYear', e.target.value)}
                                            variant="outlined"
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                            InputLabelProps={
                                                {
                                                    shrink: true
                                                }
                                            }
                                            required
                                            inputProps={
                                                {
                                                    maxLength: 4,
                                                    minLength: 4
                                                }
                                            }
                                            error={!!state?.errors?.caseYear}
                                            helperText={state?.errors?.caseYear}
                                            color={!!state?.errors?.caseYear && 'warning'}
                                        >
                                            {
                                                caseYear?.map((year) => {
                                                    return <MenuItem key={year} value={year}>{year}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Box m={.5} sx={{ maxWidth: '9vw' }}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="clerkCaseNum"
                                        label="Docket # *"
                                        value={state?.clerkCaseNum}
                                        onChange={(e) => handleFieldChange('clerkCaseNum', e.target.value)}
                                        onBlur={(e) => {
                                            if (state.citNumber.length < 1 && state.arrestNumber.length < 1) {
                                                handleBlur('clerkCaseNum', e.target.value)
                                            }
                                        }}
                                        helperText={state?.errors?.clerkCaseNum}
                                        error={!!state?.errors?.clerkCaseNum}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        color={!!state?.errors?.clerkCaseNum && 'warning'}
                                    />
                                </Box>
                                <Box m={.5} sx={{maxWidth: '9vw'} }>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="citNumber"
                                        label="Citation # *"
                                        value={state?.citNumber}
                                        onChange={(e) => handleFieldChange('citNumber', e.target.value)}
                                        onBlur={(e) => {
                                            if (state.clerkCaseNum.length < 1 && state.arrestNumber.length < 1) {
                                                handleBlur('citNumber', e.target.value)
                                            }
                                        }} helperText={state?.errors?.citNumber}
                                        error={!!state?.errors?.citNumber}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        color={!!state?.errors?.citNumber && 'warning'}
                                    />
                                </Box>
                                <Box m={.5} sx={{ maxWidth: '9vw' }}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="arrestNumber"
                                        label="Arrest # *"
                                        value={state?.arrestNumber}
                                        onChange={(e) => handleFieldChange('arrestNumber', e.target.value)}
                                        onBlur={(e) => {
                                            if (state.clerkCaseNum.length < 1 && state.citNumber.length < 1) {
                                                handleBlur('arrestNumber', e.target.value)
                                            }
                                        }} helperText={state?.errors?.arrestNumber}
                                        error={!!state?.errors?.arrestNumber}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        color={!!state?.errors?.arrestNumber && 'warning'}
                                    />
                                </Box>
                                <Box m={.5}>
                                    <TextField
                                        sx={{...textFieldStyle, maxWidth: '7vw'}}
                                        id="violationDate"
                                        label="Violation Date"
                                        type="date"
                                        value={state?.violationDate}
                                        onChange={(e) => handleFieldChange('violationDate', e.target.value)}
                                        onBlur={(e) => handleBlur('violationDate', e.target.value)}
                                        helperText={state?.errors?.violationDate}
                                        error={!!state?.errors?.violationDate}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.violationDate && 'warning'}
                                    />
                                </Box>
                                <Box m={.5}>
                                    <TextField
                                        sx={{ ...textFieldStyle, maxWidth: '7vw' }}
                                        id="dateFiled"
                                        label="Date Filed"
                                        type="date"
                                        value={state?.dateFiled}
                                        onChange={(e) => handleFieldChange('dateFiled', e.target.value)}
                                        onBlur={(e) => handleBlur('dateFiled', e.target.value)}
                                        helperText={state?.errors?.dateFiled}
                                        error={!!state?.errors?.dateFiled}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.dateFiled && 'warning'}
                                    />
                                </Box>
                                <Box m={.5}>
                                    <TextField
                                        sx={{ ...textFieldStyle, maxWidth: '7vw' }}
                                        id="courtDatetime"
                                        label="Court Date"
                                        type="date"
                                        value={state?.courtDatetime}
                                        onChange={(e) => handleFieldChange('courtDatetime', e.target.value)}
                                        onBlur={(e) => handleBlur('courtDatetime', e.target.value)}
                                        helperText={state?.errors?.courtDatetime}
                                        error={!!state?.errors?.courtDatetime}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.courtDatetime && 'warning'}
                                    />
                                </Box>
                                <Grid item sm={"auto"}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={{ ...textFieldStyle, maxWidth: '9.5vw', minWidth: '9.5vw' }}
                                            id="caseStatus"
                                            label="Case Status"
                                            name="caseStatus"
                                            select
                                            width="auto"
                                            value={state?.caseStatus}
                                            onChange={(e) => handleFieldChange('caseStatus', e.target.value)}
                                            onBlur={(e) => handleBlur('caseStatus', e.target.value)}
                                            helperText={state?.errors?.caseStatus}
                                            error={!!state?.errors?.caseStatus}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            color={!!state?.errors?.caseStatus && 'warning'}
                                        >
                                            {statusCodes?.map(({ defaultValue }) => (
                                                <MenuItem key={defaultValue} value={defaultValue}>
                                                    {defaultValue}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Box m={.5}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="lastName"
                                        label="Last"
                                        value={state?.lastName}
                                        onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                        onBlur={(e) => handleBlur('lastName', e.target.value)}
                                        helperText={state?.errors?.lastName}
                                        error={!!state?.errors?.lastName}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.lastName && 'warning'}
                                    />
                                </Box>
                                <Box m={.5}>
                                    <TextField
                                        sx={textFieldStyle}
                                        id="firstName"
                                        label="First"
                                        value={state?.firstName}
                                        onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                        onBlur={(e) => handleBlur('firstName', e.target.value)}
                                        helperText={state?.errors?.firstName}
                                        error={!!state?.errors?.firstName}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        required
                                        color={!!state?.errors?.firstName && 'warning'}
                                    />
                                </Box>
                                <Grid item sm={"auto"}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={{ ...textFieldStyle, minWidth: '10vw' }}
                                            id="midInit"
                                            label="Middle"
                                            value={state?.midInit}
                                            onChange={(e) => handleFieldChange('midInit', e.target.value)}
                                            onBlur={(e) => handleBlur('midInit', e.target.value)}
                                            helperText={state?.errors?.midInit}
                                            error={!!state?.errors?.midInit}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.midInit && 'warning'}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={.75}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="suffix"
                                            label="Suffix"
                                            name="suffix"
                                            value={state?.suffix}
                                            onChange={(e) => handleFieldChange('suffix', e.target.value)}
                                            onBlur={(e) => handleBlur('suffix', e.target.value)}
                                            helperText={state?.errors?.suffix}
                                            error={!!state?.errors?.suffix}
                                            select
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.suffix && 'warning'}
                                        >
                                            {suffixCodes?.map(({ defaultValue }) => (
                                                <MenuItem key={defaultValue} value={defaultValue}>
                                                    {defaultValue === '""' ? "N/A" : defaultValue}
                                                </MenuItem>
                                            )
                                            )}
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item sm={2.5}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="agency"
                                            label="Agency"
                                            name="agency"
                                            value={state?.agency}
                                            onChange={(e) => handleFieldChange('agency', e.target.value)}
                                            onBlur={(e) => handleBlur('agency', e.target.value)}
                                            helperText={state?.errors?.agency}
                                            error={!!state?.errors?.agency}
                                            select
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.agency && 'warning'}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                agencyCode?.map(({ code, description }) => {
                                                    return <MenuItem key={code} value={code}>{description}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={textFieldStyle}
                                            id="court"
                                            label="Court"
                                            name="court"
                                            value={state?.court}
                                            onChange={(e) => handleFieldChange('court', e.target.value)}
                                            onBlur={(e) => handleBlur('court', e.target.value)}
                                            helperText={state?.errors?.court}
                                            error={!!state?.errors?.court}
                                            select
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.court && 'warning'}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                courtNum?.map(({ courtNum, displayCourtName }) => {
                                                    return <MenuItem key={courtNum} value={courtNum}>{displayCourtName}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                                <Grid item sm={"auto"}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={{ ...textFieldStyle, minWidth: '9.5vw' }}
                                            id="division"
                                            label="Division"
                                            name="division"
                                            value={state?.division}
                                            onChange={(e) => handleFieldChange('division', e.target.value)}
                                            onBlur={(e) => handleBlur('division', e.target.value)}
                                            helperText={state?.errors?.division}
                                            error={!!state?.errors?.division}
                                            select
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            color={!!state?.errors?.division && 'warning'}
                                        >
                                            <MenuItem value={''}>N/A</MenuItem>
                                            {
                                                divisionCode?.map(({ divisionCode, name }) => {
                                                    return <MenuItem key={divisionCode} value={divisionCode}>{name}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Paper sx={{ textAlign: 'center', width: "20%", margin: "0 1vw 1vh auto", height: '14vh' }} elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                                <h4 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Case Options</h4>
                            </Paper>
                            <ButtonGroup orientation="vertical" fullWidth sx={{ paddingRight: ".5rem", paddingLeft: ".5rem", mt: '1vh' }}>
                                <Box mb={1}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "steelblue",
                                            color: "white",
                                            height: '4vh'
                                        }}
                                        onClick={createCitation}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Box>
                                <Box mb={1}>
                                    <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                        <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white", height: '4vh' }}>Cancel</Button>
                                    </Link>
                                </Box>
                            </ButtonGroup>
                        </Paper>
                    </Box>
                </form>
            </Paper >

            {
                loading && (
                    <>
                        <LinearProgress />
                    </>
                )
            }
        </>
    )
}

export default CreateCaseForm;